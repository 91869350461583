'use strict';
define([
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',
    'common/visual/visual',
    'navigation',
    'common',
    'preload',

    'text-loader!iptv-channel/edit/layout.tpl',

], function($, _, Backbone, darsan, visual, navigation, common, preload, titleEditTemplate){

    ///////////////////////////////////////////////////////////

    return Object.create(visual).extend({

        parent: null,

        create: function(el,opt)
        {
            var me = this;
            const mod = me;

            //console.log(me);
            visual.create.apply(me,arguments);

            // console.log("title.list.create in",el.attr("id"),opt);

            var channelView = Backbone.TemplateCooperView.extend({

                template: titleEditTemplate,

                navigation: navigation,
                common: common,
                
                events: {
                  'change input[type="file"]': 'imageChange',
                  'click #deleteChannel': "deleteChannel",
                  'cooper:save:success': "changed",
                },

                render: function()
                {
                    var me = this;
                    var markup = this.compiled( {me: this.model.toJSON(), tv_group: me.parent.options.tv_group, 
                      cooper: common.cooperMacro, currency: mod.currency, documents: mod.documents} );
                    this.$el.html(markup);

                    return this;
                },

        imageChange: async function(ev)
        {
          const me = this;
          const file = ev.target.files[0]
          
          if (file.type.substr(0, 6) != "image/")
          {
            return common.notifyError("Логотип канала должен быть изображением")
          }
          
          const img = new Image();
          var objectUrl = window.URL.createObjectURL(file);
          
          const promise = new Promise((resolve, reject) => 
          {
            img.onload = () => 
            {
              const width  = img.naturalWidth;
              const height = img.naturalHeight; 

              // Resolve promise with the width and height
              resolve([width, height]); 
            }
          })

          // Setting the source makes it start downloading and eventually call `onload`
          img.src = objectUrl
          
          const [w,h] = await promise
          if (w!=100 || h!=100)
          {
            return common.notifyError("Размер логотипа должен быть 100х100 пикселей")
          }
        
          var formData = new FormData()
          formData.append('file', file, me.model.get("no") + ".png")
          formData.append("replace", 1)
          
          try {
            await darsan.formData('', 'file', "/iptv", formData)
            const img = me.$el.find("#logo-img")
            const src = img.attr("src")
            img.attr("src", "")
            img.attr("src", src)
          }
          catch(err)
          {
            common.notify("Ошибка записи: " + err)
          }
        },
        
        deleteChannel()
        {
          if (!confirm("Вы действительно хотите удалить этот канал?"))
          {
            return
          }

          const id = this.model.get("id")
          
          Promise.all([
//            darsan.delete("", "iptv", `/iptv/smartup/channel/${id}`)
//            .done( () => common.notify("Канал удален из базы SmartUp") ),
//            darsan.delete("", "iptv", `/flussonic/channel/${id}`)
//              .done( () => common.notify("Канал удален из Флюсоника") ),
//            darsan.delete("", "iptv", `/astra/channel/${id}`)
//              .done( () => common.notify("Канал удален с серверов Астра") ),
          ])
          .then( () => this.model.destroy() )
          .then( () => 
          {
            common.notify("Канал удален из базы Радикс")
            navigation.goto("/iptv-channel")
          })
          .catch(err => darsan.err(err))
        },
        
        async changed(event, {value, name, old})
        {
          if (name=="no")
          {
//            darsan.delete("", "iptv", `/flussonic/no/${old}`)
//              .done( () => common.notify(`Канал ${old} удален из Флюсоника`) )
//            darsan.delete("", "iptv", `/astra/no/${old}`)
//              .done( () => common.notify(`Канал ${old} удален с серверов Астра`) )
//            await darsan.patch("", "iptv", `/iptv/smartup/no/${old}`, {no: value})
//              .done( () => common.notify("Номер канала обновлен в базе SmartUp") )
          }

          const id = this.model.get("id")
          
          if (name!="no")
          {
//            darsan.patch("", "iptv", `/iptv/smartup/channel/${id}`, {[name]: value})
 //             .done( () => common.notify("Канал также обновлен в базе SmartUp") )
          }
            
          if (name=="disabled" || name=="archive" || name=="no")
          {
            // без указания данных запрос обновляет Флюсоник по записи в базе
//            darsan.put("", "iptv", `/flussonic/channel/${id}`, {})
//              .done( () => common.notify(`Канал ${id} обновлен во Флюсонике`) )
          }
          
          if (name!="archive")
          {
            // без указания данных запрос обновляет Астры по записи в базе
//            darsan.put("", "iptv", `/astra/channel/${id}`, {})
//              .done( () => common.notify("Канал обновлен на серверах Астра") )
          }
        },

        }); // channelView

            me.view = new channelView({
                el: me.$el
            });

          me.view.parent = me;
          return preload.ensure('currency', 'doctpl').done(() => 
          {
            me.currency = preload.get(config.domain, 'currency')
            me.documents = preload.get(config.domain, 'doctpl')
          })
        },

        setState: function(state)
        {
            var me = this;
            // console.log("title.list.setState",state);

            me.state = me.state || {};
            var name = state._rest;

            if( me.state._rest == name ) return;
            me.state = state;
            
            var url = darsan.makeUrl("", "iptv", '/iptv/channel' + name);

            var MyModel = Backbone.ModelEx.extend({
                idAttribute: "id",
                url: url
            });
            
            me.view.model = new MyModel();

            ////////////////////////////
             me.view.model.fetch()
               .then(function(m)
               {
                 me.view.render();
                 const token = encodeURIComponent(localStorage.getItem('radix-token'))
                 me.$el.find("#logo-img").attr("src", darsan.makeUrl("", "file", "/public/iptv/" + me.view.model.get("no") + ".png" ))
               });
        }

    });
});